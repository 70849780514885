import { useEffect } from 'react'
import './App.css'



function App() {

  useEffect(() => {
    setTimeout(() => {
      console.log('CallpyChat', window.CallpyChat)
      //new CallpyChat({targetBoxId:'root', mode:'prod'})
    }, 1000)
  }, [])
  
  return (
    <div className="App">
      <header></header>
      <main></main>
      <footer></footer>
      <div className="chat-box" id="callpy-chat-root">

      </div>
    </div>
  );
}

export default App;
